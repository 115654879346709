import React from "react";
import { getSafe } from "../../utils/common";
import moment from "moment";
import * as util from "../../utils/common";
//// DEVELOPMENT
// import $ from 'jquery';

const NUGIT_NOTIFICATION = "NUGIT_NOTIFICATION";
const DATA_WEEKS = "data-weeks";
const DATA_NOTIFICATION_ID = "data-notificationId";
class Notification extends React.Component {
  initDomElement() {
    this.notiContainerRef = getSafe(() => $("#NotificationContainer"));
    this.notiContentRef = getSafe(() => $("#NotificationContent"));
    this.notiCloseBtnRef = getSafe(() => $("#NotificationCloseBtn"));
  }
  componentDidMount() {
    this.initDomElement();
    let { notifications, linkedPage } = this.props;
    if (Array.isArray(linkedPage)) {
      [linkedPage] = linkedPage;
    }
    notifications = notifications.map(item => item.node);
    let allPagesNotification = notifications.find(notification => {
      return getSafe(
        () =>
          notification.linkToPage
            .map(linkPage => linkPage.page)
            .indexOf("All") !== -1
      );
    });
    let currentPageNotification = notifications.find(notification => {
      return getSafe(
        () =>
          notification.linkToPage
            .map(linkPage => linkPage.id)
            .indexOf(linkedPage.id) !== -1
      );
    });
    this.updateNotificationStyleAndContent(
      currentPageNotification ? currentPageNotification : allPagesNotification
    );
  }

  updateNotificationStyleAndContent(notification) {
    if (!notification) {
      return;
    }
    this.setNotificationPosition(notification);
    this.setNotificationContent(notification);
    this.checkShowNotification(notification);
    this.addCloseBtnEventListener();
  }

  setNotificationContent(notification) {
    this.notiContentRef.html(notification.content);
    this.notiContainerRef.attr({
      [DATA_WEEKS]: notification.weeks,
      [DATA_NOTIFICATION_ID]: notification.id
    });
  }

  setNotificationPosition(notification) {
    this.notiContainerRef.css({
      "display": "none",
      "background-color": notification.backgroundColor.hex
    });  
    this.notiContainerRef.removeClass(
      "notification-bar-top notification-bar-bottom"
    );
    if (notification.notificationPosition === "Top") {
      this.notiContainerRef.addClass("notification-bar-top");
    } else {
      this.notiContainerRef.addClass("notification-bar-bottom");
    }
  }

  checkShowNotification(notification) {
    if (!notification.show) {
      return;
    }

    let currentNotificationCachedData = this.getCachedNotificationByIdFromLocalStorage(notification.id);
    let isShowNotification =
      !currentNotificationCachedData ||
      this.isPastExpiredDate(currentNotificationCachedData) ||
      !this.isTheSameContent(
        currentNotificationCachedData,
        notification.content
      );

    if (isShowNotification) {
      this.notiContainerRef.show();
    }
  }

  getCachedNotificationByIdFromLocalStorage(notificationId) {
    try {
      let notificationCachedData = this.getNugitNotificationFromLocalStorage();
      return notificationCachedData[notificationId];
    } catch (error) {
      return null;
    }
  }

  getNugitNotificationFromLocalStorage() {
    try {
      let encodedJson = localStorage.getItem(NUGIT_NOTIFICATION);
      return getSafe(() => JSON.parse(encodedJson)) || {};
    } catch (error) {}
  }

  addCloseBtnEventListener() {
    this.notiCloseBtnRef.off("click").on("click", () => {
      try {          
        let notificationsCachedData = this.getNugitNotificationFromLocalStorage();        
        let weeks = parseInt(this.notiContainerRef.attr(DATA_WEEKS));
        let notificationId = this.notiContainerRef.attr(DATA_NOTIFICATION_ID);
        if(Number.isNaN(weeks)){
            weeks = 0;
        }
        let content = this.notiContentRef.html();
        let expiredDate = moment()
          .add(weeks * 7, "days")
          .toISOString();

        let currentUrlObjData = {
            content,
            expiredDate
        }

        notificationsCachedData[notificationId] = currentUrlObjData
        util.setLocalStorageData(NUGIT_NOTIFICATION, JSON.stringify(notificationsCachedData));
        this.notiContainerRef.fadeOut();
      } catch (error) {}
    });
  }

  isTheSameContent(notificationCachedData, content) {
    try {
      return notificationCachedData.content === content;
    } catch (error) {
      return true;
    }
  }

  isPastExpiredDate(notificationCachedData) {
    try {
      return new Date() > new Date(notificationCachedData.expiredDate);
    } catch (error) {
      return true;
    }
  }

  render() {
    return <React.Fragment />;
  }
}

export default Notification;
